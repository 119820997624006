import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query planQuery {
      img0: file(relativePath: { eq: "plan1.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img1: file(relativePath: { eq: "plan2.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "plan3.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }


    }
  `)

  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.Plans" description="seo.desc.Plans" />
      <Container className="plan-container">
        <Row className="pt-4">
          <Col>
            <h3>{tt("pages.Plan.title1")}</h3>
            <p>{tt("pages.Plan.desc1")}</p>
          </Col>
        </Row>

	{ locale !== "en" && (
        <Row className="pt-4">
          <Col>
            <h4>{tt("pages.Plan.title2")}</h4>
            <p>{tt("pages.Plan.desc2")}</p>
          </Col>
        </Row>
	)}

	{ locale !== "en" && (
        <Row className="pt-4">
          <Col xl={4} lg={4} md={4} sm={4} xs={12} className="item">
            <Img
              className="rounded mx-auto d-block pb-3"
              fluid={data.img0.childImageSharp.fluid}
            />
          </Col>
          <Col xl={4} lg={4} md={4} sm={4} xs={12} className="item">
            <Img
              className="rounded mx-auto d-block pb-3"
              fluid={data.img1.childImageSharp.fluid}
            />
          </Col>
          <Col xl={4} lg={4} md={4} sm={4} xs={12} className="item">
            <Img
              className="rounded mx-auto d-block pb-3"
              fluid={data.img2.childImageSharp.fluid}
            />
          </Col>
        </Row>
	)}
      </Container>
    </Layout>
  )
}

export default ThePage
